import { render, staticRenderFns } from "./LegalText.vue?vue&type=template&id=f41b89ba&scoped=true&"
var script = {}
import style0 from "./LegalText.vue?vue&type=style&index=0&id=f41b89ba&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f41b89ba",
  null
  
)

export default component.exports